import React from "react"
import { navigate } from "gatsby-plugin-intl"

class Logout extends React.Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      localStorage.clear();
    }
    navigate('/sessions/login/');
  }

  render() {
    return null;
  }
}

export default Logout
